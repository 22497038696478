@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

.color-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00000020;
  margin-right: 7px;
  margin-left: 7px;
  width: 35px;
  border-radius: 10px;
  height: 35px;
  cursor: pointer;
}

.color-picker:hover {
  transform: scale(1.1);
}

.styles-module_appsContainerInline__86Sau {
  border: none !important;
}

.styles-module_appPageContainerCompact__2Sqpi {
  padding: 0 !important;
  border: none !important;
}

.styles-module_appCard__3kLxI {
  border-radius: 14px !important;
  box-shadow: none !important;
}

.styles-module_embedPreviewFooter__3Y6zF {
  display: none !important;
}

.styles-module_flowCard__prxMM {
  border-radius: 14px !important;
  box-shadow: none !important;
}

.styles-module_btnPrimary__2lzrP {
  background-color: #000 !important;
  background: #000 !important;
  border: none !important;
  border-radius: 20px !important;
}

.styles-module_integryScrollbar__3hnuE {
  padding-bottom: 100px !important;
}
.styles-module_appsHeader__3eU5R {
  display: none !important;
}

.styles-module_flowName__fQw9K {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.styles-module_accountFieldDropdown__3Z6Lw {
  border-radius: 24px !important;
}

.styles-module_appInfo__l2Lfx svg circle {
  stroke: none !important;
  fill: transparent !important;
}

.styles-module_appInfo__l2Lfx svg path {
  fill: #000 !important;
}

.code-text {
  font-family: 'Courier New', Courier, monospace !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* This is a semi-transparent black */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s; /* Smooth transition */
  border-radius: 13px;
}

.folder:hover {
  background-color: #00000007;
}

.icon-bg-log {
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px;
}

.cell-list {
  cursor: pointer;
  padding: 7px;
  border-right: 1px solid #f5f5f5;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.label {
  font-size: 11px;
  margin-left: 15px;
}

.block {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

#block1 {
  animation-delay: 0.4s;
}

#block2 {
  animation-delay: 1.3s;
}

#block3 {
  animation-delay: 1.8s;
}

#block4 {
  animation-delay: 2.6s;
}

#block5 {
  animation-delay: 3s;
}

#block6 {
  animation-delay: 2.1s;
}

#block7 {
  animation-delay: 1.1s;
}

#block8 {
  animation-delay: 3.5s;
}

.uppercase {
  text-transform: uppercase !important;
}

.ql-toolbar {
  border: none !important;
}

.ql-container {
  border: none !important;
  background-color: transparent !important;
}

.message-chat {
  border-radius: 0px 15px 15px 15px;
}

.fc-theme-standard td {
  border: 'none' !important;
}

.fc-theme-standard th {
  border: 'none' !important;
}

.fc-theme-standard th .message-chat-current {
  border-radius: 15px 15px 0px 15px;
}

.hide {
  display: none !important;
}

#gjs {
  border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}

.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}
.panel__basic-actions {
  position: initial;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
}
.panel__switcher {
  position: initial;
}

@keyframes firework {
  0% {
    transform: translate(var(--x), var(--initialY));
    width: var(--initialSize);
    opacity: 1;
  }
  50% {
    width: 0.5vmin;
    opacity: 1;
  }
  100% {
    width: var(--finalSize);
    opacity: 0;
  }
}

@media print {
  .no-print {
    display: none !important;
  }
}

svg.recharts-surface {
  width: 100% !important;
  height: 100% !important;
}

.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6,
.markdown-content p,
.markdown-content blockquote {
  font-size: 11px;
  font-weight: 400 !important;
  line-height: 1.2 !important;
}

.markdown-content a {
  color: var(--black-color) !important;
  text-decoration: none !important;
  font-weight: 500 !important;
}

.markdown-content ul,
.markdown-content ol,
.markdown-content li {
  font-size: 11px;
  line-height: 1.5 !important;
}

.markdown-content strong,
.markdown-content b {
  font-size: 12px;
  line-height: 1.1 !important;
  font-weight: 600 !important;
}

.markdown-content h1 {
  font-size: 22px;
  font-weight: 600 !important;
  line-height: 2.1 !important;
}

.markdown-content h2 {
  font-size: 17px;
  font-weight: 600 !important;
  line-height: 2 !important;
}

/* Add this to your CSS file or style section */
.smallChipText .MuiChip-label {
  font-size: 10px !important;
}

.MuiChip-icon {
  font-size: 12px !important;
}

.smallChipTextTags .MuiChip-label {
  font-size: 8.5px !important;
}

.float-animation {
  position: absolute;
  animation: smoothBounce 4.5s infinite;
  animation-timing-function: cubic-bezier(0.28, 0.54, 0.42, 0.7);
  box-shadow: 0 10 8 0 rgba(0, 0, 0, 0.3);
}

.absolute-container {
  position: absolute;
}

.float-animation-2 {
  animation: smoothBounce 5.5s infinite;
  animation-timing-function: cubic-bezier(0.28, 0.54, 0.42, 0.7);
  box-shadow: 0 10 8 0 rgba(0, 0, 0, 0.3);
}

.float-animation-3 {
  animation: smoothBounce 7.5s infinite;
  animation-timing-function: cubic-bezier(0.28, 0.54, 0.42, 0.8);
  box-shadow: 0 10 8 0 rgba(0, 0, 0, 0.3);
}

@keyframes smoothBounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-7px); /* Adjust for more or less bounce height */
  }
  80% {
    transform: translateY(-7px); /* Adjust for more or less bounce height */
  }
}

.tire-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.segment {
  width: 27px;
  height: 27px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 500;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 1360px) {
  .hide-on-tablet-and-below {
    display: none;
  }
}

.icon-container {
  position: relative;
  width: 24px; /* Assuming the width of the icon is 24px */
  height: 24px; /* Assuming the height of the icon is 24px */
}

.icon-bottom,
.icon-top {
  position: absolute;
  top: 0;
  left: 0;
}

.icon-bottom-top {
  margin-left: -10px;
}

.icon-top {
  z-index: 1; /* This will place the top icon above the bottom icon */
}

.brake-indicator {
  display: flex;
  gap: 5px; /* Spacing between buttons */
}

.brake-indicator .segment {
  padding: 5px 10px;
  width: 68px;
  font-size: 11px !important;
  font-weight: 500 !important;
  border: none;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.3s;
}

.battery-health-container {
  display: flex;
  gap: 10px;
}

.battery-button {
  padding: 8px 18px;
  border: none;
  height: 40px;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

.battery-button:hover {
  transform: scale(1.05);
}

.facets {
  margin-top: -13px !important;
  width: 25vh;
  justify-content: space-between;
}

.hide-on-mobile {
  display: block;
}

@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

.rbc-month-view {
  border: none !important;
}

.rbc-day-bg.rbc-off-range-bg {
  background-color: var(--accent-color-light) !important;
}

.rbc-today {
  background-color: var(--primary-color-light) !important;
}

.rbc-current-time-indicator {
  background-color: var(--primary-color) !important;
}

.rbc-event-content {
  color: var(--white-color) !important;
  font-size: 12px;
}

.rbc-event-label {
  color: var(--white-color) !important;
  font-size: 10px;
}

.rbc-toolbar-label {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.rbc-toolbar button {
  border: none !important;
}

.rbc-day-bg.rbc-today {
  background-color: var(--primary-color-light) !important;
}

.rbc-toolbar {
  background-color: transparent !important;
  border: none !important;
  margin-bottom: 20px !important;
}

.rbc-btn-group {
  border: none !important;
}

.rbc-active {
  background-color: var(--accent-color-dark) !important;
  color: var(--primary-color-dark) !important;
  border: none !important;
}

.firework::after {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
  transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
}

.firework:nth-child(2) {
  --x: 30vmin;
}

.firework:nth-child(2),
.firework:nth-child(2)::before,
.firework:nth-child(2)::after {
  --color1: var(--primary-color);
  --color2: var(--primary-color-dark);
  --color3: var(--primary-color-light);
  --color4: var(--primary-color);
  --color5: plum;
  --color6: lavender;
  --finalSize: 40vmin;
  left: 30%;
  top: 60%;
  animation-delay: -0.25s;
}

.firework:nth-child(3) {
  --x: -30vmin;
  --y: -50vmin;
}

.firework:nth-child(3),
.firework:nth-child(3)::before,
.firework:nth-child(3)::after {
  --color1: var(--secondary-color);
  --color2: var(--secondary-color-dark);
  --color3: var(--secondary-color-light);
  --color4: PaleTurquoise;
  --color5: SkyBlue;
  --color6: lavender;
  --finalSize: 35vmin;
  left: 70%;
  top: 60%;
  animation-delay: -0.4s;
}

.hei-1-5 {
  height: 10vh;
  overflow: scroll;
}

.hei-3 {
  height: 23vh;
  overflow: scroll;
}

.hei-4 {
  height: 31vh;
  overflow: scroll;
}

.hei-5 {
  height: 39vh;
  overflow: scroll;
}

.hei-7 {
  height: 55vh;
  overflow: scroll;
}

.hei-8 {
  height: 63vh;
  overflow: scroll;
}

.hei-9 {
  height: 71vh;
  overflow: scroll;
}

.hei-page {
  height: 79vh;
  overflow: scroll;
}

.h-page {
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 80vh !important;
  max-height: 80vh !important;
}

.h-40 {
  height: 40vh !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 40vh !important;
  max-height: 40vh !important;
  overflow: auto;
}

.dot {
  height: 1.5vh;
  width: 1.5vh;
  background-color: var(--primary-color-dark);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin-left: -2.2vh;
  margin-bottom: -0.8vh;
}

abbr {
  border: none !important;
  text-decoration: none !important;
}

.rdrDayToday {
  color: var(--primary-color) !important;
}

.hover:hover {
  cursor: pointer;
}

.align-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.align-right {
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.align-left {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

#container-appointment::-webkit-scrollbar {
  width: 5px !important;
  background: #000 !important;
}

#container-appointment::-webkit-scrollbar-track {
  background: #444 !important;
  margin-right: 5px !important;
}

#container-appointment::-webkit-scrollbar-thumb {
  background: #757575 !important;
  opacity: 1 !important;
  border-radius: 20px !important;
}

html
  body
  .fc-scrollgrid-section
  .fc-scrollgrid-section-footer
  .fc-scrollgrid-section-sticky {
  display: inline-block !important;
}

::-webkit-scrollbar {
  width: 5px;
  overflow-x: hidden;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin-right: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  opacity: 0.5;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--accent-color-dark);
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.icon-create:hover + .menu-list {
  display: block;
}

.menu-list {
  width: 250px;
  position: absolute;
  top: 7vh;
  right: 0;
  border-radius: 15px;
  margin-right: 40px;
  background-color: var(--white-color);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 6px;
  border: 0.5px solid var(--black-color);
  z-index: 1000;
}

.menu-item {
  cursor: pointer;
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.menu-item:hover {
  cursor: pointer;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: 500;
}

div.row.d-flex {
  width: 100%;
}

.container-scan {
  margin-left: 3vh;
}

div.row {
  width: 100%;
}

.rdrDateDisplayWrapper {
  background-color: transparent !important;
}

.rdrMonthName {
  display: none !important;
}

.rdrStartEdge.rdrEndEdge {
  border-radius: 4px !important;
}

.rdrDateDisplay {
  display: none !important;
}

.rdrInputRanges {
  display: none !important;
}

.overflow-scroll {
  overflow: scroll;
}

.btn-modal-large {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}

.modal-content {
  padding: 1vh;
}

svg {
  margin: 0px;
}

.middle-content {
  align-items: center;
}

.grey-text {
  color: #949494 !important;
}

.success-text {
  color: #bddc11 !important;
}

.error-text {
  color: #e72b57 !important;
}

.fc-license-message {
  display: none;
}

.fc .fc-day-today {
  background: none !important;
}

.fc-timeline-slot {
  border: none !important;
}

.fc-col-header-cell-cushion {
  color: #000 !important;
  font-size: 10px !important;
}

.fc-list-day-side-text {
  color: #000 !important;
  font-size: 10px !important;
}

.fc-list-day-text {
  color: #000 !important;
  font-size: 10px !important;
}

.fc-timegrid-axis-cushion {
  display: none !important;
}

.fc-list {
  border: none !important;
}

.fc-toolbar-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: var(--black-color) !important;
}

.fc-timegrid-now-indicator-arrow {
  display: none !important;
}

.fc-timegrid-now-indicator-line {
  border: #00000040 0.5px solid !important;
}

.fc-cell-shaded {
  background-color: transparent !important;
}

.fc-timeline-now-indicator-arrow {
  display: none !important;
}

.fc-timeline-now-indicator-line {
  border: #00000040 0.5px solid !important;
}

.fc-theme-standard {
  border: none !important;
}

.fc-button-primary {
  background-color: var(--white-color) !important;
  border: none !important;
  color: #000 !important;
  font-size: 15px !important;
}

.fc-event {
  outline: none !important;
  overflow: hidden !important;
}

.fc-toolbar-chunk {
  border: none !important;
}

.fc-button-active {
  font-weight: 600 !important;
  font-size: 17px !important;
}

.fc-event-time {
  color: rgb(255, 255, 255) !important;
  font-size: 10px !important;
  font-weight: 300 !important;
}

.fc-timeline-slot-cushion {
  color: #757575 !important;
  font-weight: 500;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-resource-timeline-divider .fc-cell-shaded {
  border: none !important;
}

.sigCanvas {
  background-color: #f3f3f3 !important;
  border-radius: 10px;
}

.fc-resource-timeline-divider {
  border: none !important;
}

.fc-datagrid-cell {
  border-bottom: none !important;
}

.fc-event {
  border-radius: 10px !important;
  border: none !important;
  padding: 5px;
}

.fc-event-title {
  color: rgb(255, 255, 255) !important;
  font-size: 11px !important;
  font-weight: 600 !important;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.module-slide-in {
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0; /* Initially hidden */
}

.fc-resource-timeline {
  border: none !important;
}

.italic {
  font-style: italic;
  color: #959db4;
}

.menu-results-holder {
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 8px;
  height: 32vh;
  width: 55vh;
  overflow: auto !important;
  border-radius: 1vh;
  box-shadow: 0px 0px 10px 0px #00000020;
}

.client-modal-body.payment {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

div.MuiDataGrid-panelFooter.css-4rdffl-MuiDataGrid-panelFooter {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-right: 20px;
}

.MuiDataGrid-cellContent {
  font-size: 12px !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  font-size: 11px;
}

.MuiDataGrid-root {
  border: none !important;
}

div.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--sideRight {
  display: none;
}

.scan-qr-container {
  height: 21.5vh;
  width: 21.5vh;
}

.full-width {
  width: 100% !important;
}

.reactions-display {
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  justify-content: flex-start;
  margin-top: 3px;
  border-radius: 30px;
  height: 35px;
  padding: 0px 5px 0px 5px;
  background-color: #ffffff;
  border: rgba(255, 255, 255, 0.4);
}

.reaction-button {
  border: none !important;
  background-color: transparent;
  margin-right: 6px;
}

.d-flex-3d {
  display: flex;
  perspective: 500px; /* Optional: gives a sense of depth; adjust as needed */
}

.status-square {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  color: #fff;
  height: 20px; /* Adjust based on your needs */
  line-height: 20px; /* Center the text vertically */
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.3),
    2px -2px 5px rgba(255, 255, 255, 0.3); /* 3D shadow effect */
  transition: transform 0.3s ease; /* Smooth transformation on hover */
}

.status-square:hover {
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.status-square:first-child {
  border-radius: 10px 0 0 10px;
}

.status-square:last-child {
  border-radius: 0 10px 10px 0;
}

.custom-link-node {
  background-color: #f5f5f5;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 11px;
  cursor: pointer;
}
